// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { NewPost, ContactUs, RequestGame, ReportBug, TourneySignUp, Errors, Post, NFTCollection, Club, User, ClubPost, ClubUser } = initSchema(schema);

export {
  NewPost,
  ContactUs,
  RequestGame,
  ReportBug,
  TourneySignUp,
  Errors,
  Post,
  NFTCollection,
  Club,
  User,
  ClubPost,
  ClubUser
};