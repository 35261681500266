import React from 'react';
import './splash.css';
import { Image } from 'react-bootstrap';
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import newLogo from './assets/banner/newLogo.svg';

export default function SplashScreen() {
  return (
    <div
      className="mx-auto my-auto"
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
      }}
    >
     <Image
            alt=""
            src={require("./assets/images/reverseSplash.gif")}
            style={{  display:'flex',border:'0px',objectFit:'contain',background:'transparent' ,}}
            className="mx-auto my-auto img-fluid"
            rounded
          />
       
   
    </div>
  );
}
